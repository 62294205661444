import React from 'react';
import { NavLink, Link } from 'react-router-dom';

export default function Sidebar() {
  return (
    <div className="iq-sidebar">
      {' '}
      <div className="iq-sidebar-logo d-flex justify-content-between">
        <Link to="/dashboard">
          <div className="iq-light-logo">
            <img
              src="https://res.cloudinary.com/djnhrvjyf/image/upload/v1619705270/logo_inblwi.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="iq-dark-logo">
            <img
              src="https://res.cloudinary.com/djnhrvjyf/image/upload/v1619705270/logo_inblwi.png"
              className="img-fluid"
              alt=""
            />
          </div>
          {/* <span>Wheretogo</span> */}
        </Link>
        <div className="iq-menu-bt-sidebar">
          <div className="iq-menu-bt align-self-center">
            <div className="wrapper-menu">
              <div className="main-circle">
                <i className="ri-arrow-left-s-line"></i>
              </div>
              <div className="hover-circle">
                <i className="ri-arrow-right-s-line"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            <li className="iq-menu-title">
              <i className="ri-subtract-line"></i>
              <span>Dashboard</span>
            </li>
            <li>
              <NavLink to="/dashboard" className="iq-waves-effect">
                <i className="ri-home-4-line"></i>
                <span>Dashboard </span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/users" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="icomoon-free:users"
                  data-inline="false"
                  style={{ fontSize: "18px" }}
                ></span>
                <span style={{ paddingLeft: "15px" }}>Users </span>
              </NavLink>
            </li> */}

            <li>
              <NavLink to="/country" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="subway:world-1"
                  data-inline="false"
                  style={{ fontSize: '18px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>Country </span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/city" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="emojione-monotone:world-map"
                  data-inline="false"
                  style={{ fontSize: '18px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>City </span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/places" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="fluent:globe-location-20-filled"
                  data-inline="false"
                  style={{ fontSize: '20px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>Places </span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/category" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="el:list-alt"
                  data-inline="false"
                  style={{ fontSize: '19px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>Category </span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/comments" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="uil:comment-image"
                  data-inline="false"
                  style={{ fontSize: '20px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>Comments </span>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/conversations"
                className="iq-waves-effect"
              >
                <span
                  className="iconify"
                  data-icon="il:conversation"
                  data-inline="false"
                  style={{ fontSize: '18px' }}
                ></span>
                <span style={{ paddingLeft: '15px' }}>Replies </span>
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink to="/subcategory" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="carbon:list-dropdown"
                  data-inline="false"
                  style={{ fontSize: "20px" }}
                ></span>
                <span style={{ paddingLeft: "15px" }}>Subcategory </span>
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink
                to="/free_facilities"
                className="iq-waves-effect"
              >
                <span
                  className="iconify"
                  data-icon="emojione-monotone:person-in-bed"
                  data-inline="false"
                  style={{ fontSize: '22px' }}
                ></span>
                <span
                  style={{
                    paddingLeft: '15px',
                    marginBottom: '-5px',
                  }}
                >
                  Free facilities{' '}
                </span>
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink to="/notifications" className="iq-waves-effect">
                <span
                  className="iconify"
                  data-icon="clarity:notification-solid-badged"
                  data-inline="false"
                  style={{ fontSize: "21px" }}
                ></span>
                <span style={{ paddingLeft: "15px", marginBottom: "-6px" }}>
                  Push Notifications
                </span>
              </NavLink>
            </li> */}
          </ul>
        </nav>
        <div className="p-3"></div>
      </div>
    </div>
  );
}
