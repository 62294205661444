import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { db } from "../../firebase";
import AdminWrapper from '../../components/common/AdminWrapper';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from '../../components/common/Pagination';
import api from '../../api';
import { parseMessage } from '../../utility/Error';

export default () => {
  const params = useParams();
  const { placeId } = params;
  const [comments, setComments] = useState([]);
  const [pureComments, setPureComments] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedPageCount, setPaginatedPageCount] = useState(0);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteKey, setDeleteKey] = useState(false);

  const onSearch = (val) => {
    setQuery(val);
    let text = val.toLowerCase();
    document.querySelectorAll('#search-item').forEach((dataRow) => {
      const name = dataRow.childNodes[1].textContent;
      if (name.toLowerCase().indexOf(text) !== -1) {
        dataRow.style.display = 'table-row';
      } else {
        dataRow.style.display = 'none';
      }
    });
  };

  const gotoPage = (pageNumber) => {
    if (pageNumber != currentPage) {
      setCurrentPage(pageNumber);
      setComments([]);
      getComments(pageNumber, perPage, true);
    }
  };

  const gotoNext = () => {
    if (
      Object.keys(paginationData).length > 0 &&
      paginationData.next
    ) {
      setCurrentPage(currentPage + 1);
      setComments([]);
      getComments(currentPage + 1, perPage, true);
    }
  };

  const prevPage = () => {
    if (
      Object.keys(paginationData).length > 0 &&
      paginationData.prev
    ) {
      setCurrentPage(currentPage - 1);
      setComments([]);
      getComments(currentPage - 1, perPage, true);
    }
  };

  const dataRequest = (pageNumber, perPage, enableReload) => {
    if (enableReload) {
      setLoading(true);
    }

    return api()
      .get(
        `/admin/places/${placeId}/comments?page=${pageNumber}&limit=${perPage}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => setLoading(false));
  };

  const getComments = (cPage, perPage, enableReload) => {
    dataRequest(cPage, perPage, enableReload)
      .then((res) => {
        setPaginationData(res.links);
        setComments(res.data);
        setPureComments(res.data);
        if (res.meta.total % perPage == 0) {
          setPaginatedPageCount(Math.floor(res.meta.total / perPage));
        } else {
          setPaginatedPageCount(
            Math.floor(res.meta.total / perPage) + 1
          );
        }
      })
      .catch((err) => {
        parseMessage(err);
      });
  };

  const refreshPaginatedData = (data) => {
    setPerPage(data);
    getComments(1, data, true);
  };

  const confirmDelete = (data) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteComment(data),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteComment = (id) => {
    setDeleting(true);
    setDeleteKey(id);
    api()
      .delete(`/admin/comments/${id}`)
      .then((res) => {
        if (res.status == 204) {
          toast.success('Comment deleted successfully');
          getComments(currentPage, perPage, true);
        }
        setDeleting(false);
        setDeleteKey('');
      })
      .catch((err) => {
        parseMessage(err);
        setDeleting(false);
      });
  };

  let container;

  if (loading) {
    container = (
      <>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
      </>
    );
  } else {
    if (Object.keys(comments).length > 0) {
      container = comments.map((item, index) => (
        <tr key={index} id="search-item">
          <td>{index + 1}</td>
          <td>{item.name}</td>
          <td>{item.comment}</td>
          <td>{item.likes}</td>
          <td>
            {new Date(item.createdAt).toISOString().slice(0, 10)}
          </td>
          <td>
            {item.isDelete ? (
              <span className="badge badge-pill iq-bg-danger">
                Deleted
              </span>
            ) : (
              <span className="badge badge-pill iq-bg-success">
                Active
              </span>
            )}
          </td>
          <td>
            {deleteKey == item.id && deleting ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                <i
                  className="ri-delete-bin-5-line text-danger action-badge-size"
                  onClick={() => confirmDelete(item.id)}
                ></i>
              </>
            )}
          </td>
        </tr>
      ));
    } else {
      container = (
        <tr>
          <td></td>
          <td></td>
          <td>No information at the moment</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }

  useEffect(() => {
    getComments(1, perPage, true);
  }, []);

  return (
    <AdminWrapper>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Comments</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row mb-3">
            <div className="col-2 mr-auto"></div>
            <div className="col-5"></div>
            <div className="col-2">
              <select
                defaultValue={perPage}
                className="form-control pagination-dropdown"
                onChange={(e) =>
                  refreshPaginatedData(parseInt(e.target.value))
                }
              >
                <option value="10">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="col-3 ml-auto">
              <input
                className="form-control"
                defaultValue={query}
                type="text"
                placeholder="Search category..."
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Place</th>
                <th scope="col">Comment</th>
                <th scope="col">Likes</th>
                <th scope="col">Date Added</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{container}</tbody>
          </table>
          <Pagination
            gotoNext={gotoNext}
            currentPage={currentPage}
            prevPage={prevPage}
            gotoPage={gotoPage}
            paginatedPageCount={paginatedPageCount}
          />
        </div>
      </div>
    </AdminWrapper>
  );
};
