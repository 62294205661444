import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from './pages/Auth/Auth';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import Country from './pages/Country';
import City from './pages/City';
import Category from './pages/Category';
// import Subcategory from "./pages/Subcategory";
import Facilities from './pages/Facilities';
// import Conversations from "./pages/Conversations";
// import Replies from "./pages/Replies";
import Comments from './pages/Comments';
// import Notifications from "./pages/Notifications";
import Place from './pages/Place';
import AddPlace from './pages/Place/AddPlace';
// import Users from "./pages/Users";
import EditPlace from './pages/Place/EditPlace';

function App() {
  return (
    <Router>
      <ToastContainer autoClose={4000} />
      <Switch>
        <Route component={Auth} exact path="/" />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/country" component={Country} />
        <PrivateRoute exact path="/city" component={City} />
        <PrivateRoute exact path="/places" component={Place} />
        <PrivateRoute exact path="/add_place" component={AddPlace} />
        <PrivateRoute exact path="/category" component={Category} />
        <PrivateRoute
          exact
          path="/places/:placeKey/:cityKey"
          component={EditPlace}
        />
        <PrivateRoute
          exact
          path="/comments/:placeId"
          component={Comments}
        />
        <PrivateRoute
          exact
          path="/free_facilities"
          component={Facilities}
        />
        {/*   
        <PrivateRoute exact path="/subcategory" component={Subcategory} />
        <PrivateRoute exact path="/conversations" component={Conversations} />
        <PrivateRoute exact path="/replies/:convoId" component={Replies} />
        <PrivateRoute exact path="/comments" component={Comments} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/places" component={Place} />
        
        <PrivateRoute exact path="/add_place" component={AddPlace} />
        <PrivateRoute exact path="/users" component={Users} /> */}
      </Switch>
    </Router>
  );
}

export default App;
