import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../../components/common/AdminWrapper';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { db } from "../../firebase";
import Pagination from '../../components/common/Pagination';
import api from '../../api';
import { parseMessage } from '../../utility/Error';

export default () => {
  const [cities, setCities] = useState([]);
  const [places, setPlaces] = useState([]);
  const [purePlaces, setPurePlaces] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cityId, setCityId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState('');
  const [deleteKey, setDeleteKey] = useState('');
  const [trendKey, setTrendKey] = useState('');
  const [ratingKey, setRatingKey] = useState('');
  const [offerKey, setOfferKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loadingPlaces, setLoadingPlaces] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [paginatedPageCount, setPaginatedPageCount] = useState(0);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingOffer, setUpdatingOffer] = useState(false);

  const getCities = () => {
    setLoading(true);
    api()
      .get('/admin/city')
      .then((res) => {
        setCities(res.data.data);
        setCityId(res.data.data[0].id);
        setLoading(false);
        getPlaces(1, res.data.data[0].id, perPage, true);
      })
      .catch((err) => {
        parseMessage(err);
        setLoading(false);
      });
  };

  const dataRequest = (pageNumber, cityId, perPage, enableReload) => {
    if (enableReload) {
      setLoadingPlaces(true);
    }

    return api()
      .get(`/city/${cityId}/all?page=${pageNumber}&limit=${perPage}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      })
      .finally(() => setLoadingPlaces(false));
  };

  const getPlaces = (cPage, cityId, perPage, enableReload) => {
    dataRequest(cPage, cityId, perPage, enableReload)
      .then((res) => {
        setPaginationData(res.links);
        setPlaces(res.data);
        setPurePlaces(res.data);
        if (res.meta.total % perPage == 0) {
          setPaginatedPageCount(Math.floor(res.meta.total / perPage));
        } else {
          setPaginatedPageCount(
            Math.floor(res.meta.total / perPage) + 1
          );
        }
      })
      .catch((err) => {
        parseMessage(err);
      });
  };

  const getCategory = () => {
    setIsLoading(true);
    api()
      .get('/categories')
      .then((res) => {
        let all = { value: 'All', name: 'All' };
        let data = res.data;
        let newData = [all, ...data];
        setCategories(newData);
        setCategoryId(newData[0].value);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setIsLoading(false));
  };

  const onSearch = (val) => {
    setQuery(val);
    let text = val.toLowerCase();
    document.querySelectorAll('#search-item').forEach((dataRow) => {
      const name = dataRow.childNodes[1].textContent;
      const city = dataRow.childNodes[2].textContent;
      if (
        name.toLowerCase().indexOf(text) !== -1 ||
        city.toLowerCase().indexOf(text) !== -1
      ) {
        dataRow.style.display = 'table-row';
      } else {
        dataRow.style.display = 'none';
      }
    });
  };

  const populatePlaces = (data) => {
    setCityId(data);
    setCurrentPage(1);
    getPlaces(1, data, perPage, true);
  };

  const filterPlace = (key) => {
    if (key === 'all') {
      setPlaces(purePlaces);
    } else {
      let newData = purePlaces
        .slice()
        .filter((place) => place.categories.includes(key));
      setPlaces(newData);
    }
  };

  const refreshPaginatedData = async (data) => {
    setPerPage(data);
    getPlaces(1, cityId, data, true);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deletePlace(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deletePlace = (id) => {
    setDeleting(true);
    setDeleteKey(id);
    api()
      .delete(`/admin/places/${id}`)
      .then((res) => {
        if (res.status == 204) {
          toast.success('Place deleted successfully');
          getPlaces(currentPage, cityId, perPage, false);
        }
        setDeleting(false);
        setDeleteKey('');
      })
      .catch((err) => {
        parseMessage(err);
        setDeleting(false);
      });
  };

  const gotoPage = (pageNumber) => {
    if (pageNumber != currentPage) {
      setCurrentPage(pageNumber);
      setPlaces([]);
      getPlaces(pageNumber, cityId, perPage, true);
    }
  };

  const gotoNext = () => {
    if (
      Object.keys(paginationData).length > 0 &&
      paginationData.next
    ) {
      setCurrentPage(currentPage + 1);
      setPlaces([]);
      getPlaces(currentPage + 1, cityId, perPage, true);
    }
  };

  const prevPage = () => {
    if (
      Object.keys(paginationData).length > 0 &&
      paginationData.prev
    ) {
      setCurrentPage(currentPage - 1);
      setPlaces([]);
      getPlaces(currentPage - 1, cityId, perPage, true);
    }
  };

  const addBestRating = (id, status) => {
    setRatingKey(id);
    setUpdatingStatus(true);
    api()
      .patch(`/admin/places/${id}`, { isBestRating: status })
      .then((res) => {
        setUpdatingStatus(false);
        setRatingKey('');
        getPlaces(currentPage, cityId, perPage, false);
      })
      .catch((err) => {
        parseMessage(err);
        setUpdatingStatus(false);
      });
  };

  const addTrending = (id, status) => {
    setTrendKey(id);
    setUpdatingStatus(true);
    api()
      .patch(`/admin/places/${id}`, { isTrending: status })
      .then((res) => {
        setUpdatingStatus(false);
        setTrendKey('');
        getPlaces(currentPage, cityId, perPage, false);
      })
      .catch((err) => {
        parseMessage(err);
        setUpdatingStatus(false);
      });
  };

  const addOffer = (id, status) => {
    setOfferKey(id);
    setUpdatingOffer(true);

    api()
      .patch(`/admin/places/${id}`, { offer: status })
      .then((res) => {
        setUpdatingOffer(false);
        setOfferKey('');
        getPlaces(currentPage, cityId, perPage, false);
      })
      .catch((err) => {
        parseMessage(err);
        setUpdatingOffer(false);
      });
  };

  useEffect(() => {
    getCities();
    getCategory();
  }, []);

  let container, cityContainer, categoryContainer;

  if (loading) {
    cityContainer = (
      <div>
        <i
          className="fas fa-circle-notch fa-spin"
          style={{ color: '#F78567', fontSize: '25px' }}
        ></i>
      </div>
    );
  } else {
    if (Object.keys(cities).length > 0) {
      cityContainer = (
        <div>
          <select
            className="form-control select-control"
            onChange={(e) => populatePlaces(e.target.value)}
            value={cityId}
          >
            {cities.map((city, index) => (
              <option key={index} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
  }

  if (isLoading) {
    categoryContainer = (
      <div>
        <i
          className="fas fa-circle-notch fa-spin"
          style={{ color: '#F78567', fontSize: '25px' }}
        ></i>
      </div>
    );
  } else {
    if (Object.keys(categories).length > 0) {
      categoryContainer = (
        <div>
          <select
            className="form-control select-control"
            onChange={(e) => filterPlace(e.target.value)}
          >
            {categories.map((category, index) => (
              <option key={index} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
  }

  if (loadingPlaces) {
    container = (
      <>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
      </>
    );
  } else {
    if (Object.keys(places).length > 0) {
      let i = 1;
      container = places.map((item, index) => (
        <tr key={index} id="search-item">
          <td>{i++}</td>
          <td>{item.name}</td>
          <td>
            {item.isBestRating ? 'Yes' : 'No'}
            {item.isBestRating ? (
              <>
                {updatingStatus && item.id == ratingKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-danger ml-2 pointer"
                    onClick={() => addBestRating(item.id, false)}
                  >
                    Remove
                  </span>
                )}
              </>
            ) : (
              <>
                {updatingStatus && item.id == ratingKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-success ml-2 pointer"
                    onClick={() => addBestRating(item.id, true)}
                  >
                    Add
                  </span>
                )}
              </>
            )}
          </td>
          <td>
            {item.isTrending ? 'Yes' : 'No'}
            {item.isTrending ? (
              <>
                {updatingStatus && item.id == trendKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-danger ml-2 pointer"
                    onClick={() => addTrending(item.id, false)}
                  >
                    Remove
                  </span>
                )}
              </>
            ) : (
              <>
                {updatingStatus && item.id == trendKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-success ml-2 pointer"
                    onClick={() => addTrending(item.id, true)}
                  >
                    Add
                  </span>
                )}
              </>
            )}
          </td>
          <td>
            {item.offer ? 'Yes' : 'No'}
            {item.offer ? (
              <>
                {updatingOffer && item.id == offerKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-danger ml-2 pointer"
                    onClick={() => addOffer(item.id, false)}
                  >
                    Remove
                  </span>
                )}
              </>
            ) : (
              <>
                {updatingOffer && item.id == offerKey ? (
                  <i className="fa fa-spinner fa-spin ml-2" />
                ) : (
                  <span
                    className="badge badge-pill iq-bg-success ml-2 pointer"
                    onClick={() => addOffer(item.id, true)}
                  >
                    Add
                  </span>
                )}
              </>
            )}
          </td>
          <td>{item.rating}</td>
          <td>{item.userCheckinCount}</td>
          <td>
            {item.deactived ? (
              <span className="badge badge-pill iq-bg-danger">
                Deleted
              </span>
            ) : (
              <span className="badge badge-pill iq-bg-success">
                Active
              </span>
            )}
          </td>
          <td>
            <Link to={`/places/${item.id}/${cityId}`}>
              <i className="ri-ball-pen-fill text-success pr-1 action-badge-size"></i>
            </Link>
            &nbsp;
            <Link to={`/comments/${item.id}`}>
              <span
                className="iconify action-badge-size pr-1 comment-icon"
                data-icon="bx:comment-detail"
              ></span>
              &nbsp;
            </Link>
            {deleteKey == item.id && deleting ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                <i
                  className="ri-delete-bin-5-line text-danger action-badge-size"
                  onClick={() => confirmDelete(item.id)}
                ></i>
              </>
            )}
          </td>
        </tr>
      ));
    } else {
      container = (
        <tr>
          <td colSpan={7} className="text-center">
            No data found
          </td>
        </tr>
      );
    }
  }

  return (
    <>
      <AdminWrapper>
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">Places</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <div className="row mb-3">
              <div className="col-2 mr-auto">{cityContainer}</div>
              <div className="col-3 mr-auto">{categoryContainer}</div>
              <div className="col-2"></div>
              <div className="col-2">
                <select
                  defaultValue={perPage}
                  className="form-control pagination-dropdown"
                  onChange={(e) =>
                    refreshPaginatedData(parseInt(e.target.value))
                  }
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
              <div className="col-3 ml-auto">
                <input
                  className="form-control"
                  defaultValue={query}
                  type="text"
                  placeholder="Search place..."
                  onChange={(e) => onSearch(e.target.value)}
                />
              </div>
            </div>
            <p>
              <Link to="/add_place" className="btn btn-primary">
                Add place
              </Link>
            </p>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Best rating</th>
                  <th scope="col">Trending</th>
                  <th scope="col">Offers</th>
                  <th scope="col">Rating</th>
                  <th scope="col">Checkins</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>{container}</tbody>
            </table>
            <Pagination
              gotoNext={gotoNext}
              currentPage={currentPage}
              prevPage={prevPage}
              gotoPage={gotoPage}
              paginatedPageCount={paginatedPageCount}
            />
          </div>
        </div>
      </AdminWrapper>
    </>
  );
};
