export const isNull = (value) =>
  value === null ||
  value === undefined ||
  value === '' ||
  value === 'null';

export const convertImageToBase64 = async (file) => {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const result = await toBase64(file);
  return result;
};

export const fixCategories = (categories, selectedCategories) => {
  let result = [];

  const newCategories = selectedCategories
    .slice()
    .filter((category) => {
      return categories.slice().filter((c) => c.id == category.id);
    });

  newCategories.forEach((item) => {
    result.push({
      id: item.id,
      value: item.id,
      label: item.name,
    });
  });

  return result;
};
