import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import api from '../../api';
import { convertImageToBase64, isNull } from '../../utility';
import { parseMessage } from '../../utility/Error';

const customStyles = {
  content: {
    top: '51%',
    left: '50%',
    right: 'auto',
    bottom: '0',
    width: '43%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default function CategoryModal({
  closeModal,
  isOpen,
  fetchCategories,
  categoryData,
  control,
}) {
  const [name, setName] = useState('');
  const [activeicon, setActiveIcon] = useState({});
  const [inActiveIcon, setInActiveIcon] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const prefillForm = () => {
    setName(categoryData?.name);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (control === 'Add') {
      if (isNull(activeicon?.name)) {
        return toast.error('Select an active icon to continue');
      }

      if (isNull(inActiveIcon?.name)) {
        return toast.error('Select an active icon to continue');
      }

      setLoading(true);

      const inactiveIcon = await convertImageToBase64(inActiveIcon);
      const activeIcon = await convertImageToBase64(activeicon);

      const payload = {
        name,
        inactiveIcon,
        activeIcon,
      };

      api()
        .post('/admin/categories', payload)
        .then((res) => {
          setLoading(false);
          toast.success('Category successfully added!');
          fetchCategories();
          closeModal();
        })
        .catch((err) => {
          parseMessage(err);
          setLoading(false);
        });
    } else {
      const payload = {};

      setLoading(true);

      if (!isNull(activeicon?.name)) {
        const activeIcon = await convertImageToBase64(activeicon);
        payload.activeIcon = activeIcon;
      }

      if (!isNull(inActiveIcon?.name)) {
        const inactiveIcon = await convertImageToBase64(inActiveIcon);
        payload.inactiveIcon = inactiveIcon;
      }

      payload.name = name;

      api()
        .patch(`/admin/categories/${categoryData.id}`, payload)
        .then((res) => {
          toast.success('Category information updated!');
          fetchCategories();
          setLoading(false);
          closeModal();
        })
        .catch((err) => {
          parseMessage(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    prefillForm();
  }, [categoryData]);

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={closeModal}
    >
      <div className="p-2">
        <p className="text-center">{control} category</p>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="name"
              required
              defaultValue={name}
              placeholder="Enter category name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              Active Icon <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              accept="image/svg,image/svg+xml"
              onChange={(e) => setActiveIcon(e.target.files[0])}
              className="form-control"
            />
            <p className="text-danger">Icon must be svg format</p>
          </div>
          <div className="form-group">
            <label>
              Inactive Icon <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              onChange={(e) => setInActiveIcon(e.target.files[0])}
              className="form-control"
              accept="image/svg,image/svg+xml"
            />
            <p className="text-danger">Icon must be svg format</p>
          </div>

          <div className="text-center mt-4">
            {loading && (
              <>
                <i
                  className="fas fa-circle-notch fa-spin"
                  style={{ color: '#F78567', marginRight: 20 }}
                ></i>
              </>
            )}
            {!loading && (
              <>
                <button
                  type="submit"
                  className="btn btn-md btn-primary loading m-2"
                >
                  Save Changes
                </button>
              </>
            )}
            <button
              className="btn btn-md btn-secondary"
              onClick={closeModal}
            >
              Dismiss
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
