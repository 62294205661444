import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AdminWrapper from '../../components/common/AdminWrapper';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from '../../api';
import { parseMessage } from '../../utility/Error';
import { convertImageToBase64 } from '../../utility';

export default function AddPlace() {
  const pickerRef = useRef();
  const selectRef = useRef();

  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState('');
  const [loadingCities, setLoadingCities] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [loadingCategories, setLoadingCategories] = useState(false);

  const [facilitiesData, setFacilitiesData] = useState([]);
  const [loadingFacilities, setLoadingFacilities] = useState(false);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [categories, setCategories] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [paidFacilities, setPaidFacilities] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [phone, setPhone] = useState('');
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [email, setEmail] = useState('');
  const [openningTime, setOpenningTime] = useState('');
  const [openningDay, setOpenningDay] = useState('');
  const [website, setWebsite] = useState('');
  const [checkIn, setCheckin] = useState('');
  const [checkout, SetCheckout] = useState('');
  const [facebook, setFaceBook] = useState('');
  const [star, setStar] = useState('');
  const [twitter, setTwitter] = useState('');
  const [timeSpend, setTimeSpend] = useState('');
  const [description, setDescription] = useState('');
  const [booking, setBooking] = useState('');
  const [hostelWorld, setHostelWorld] = useState('');
  const [airbnb, setAirbnb] = useState('');
  const [thingsToNote, setThingsToNote] = useState('');
  const [multiFiles, setMultiFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCities = () => {
    setLoadingCities(true);
    api()
      .get('/admin/city')
      .then((res) => {
        setCityId(res.data.data[0].id);
        setCities(res.data.data);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoadingCities(false));
  };

  const getCategories = () => {
    setLoadingCategories(true);
    api()
      .get('/categories')
      .then((res) => {
        let dataObject = [];
        res.data.forEach((item) => {
          dataObject.push({
            id: item.id,
            value: item.id,
            label: item.name,
          });
        });
        setCategoryData(dataObject);
        setCategoryId(dataObject[0].id);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoadingCategories(false));
  };

  const getFacilities = () => {
    setLoadingFacilities(true);
    api()
      .get('/admin/facilities')
      .then((res) => {
        let dataObject = [];
        res.data.forEach((item) => {
          dataObject.push({
            id: item.id,
            value: item.id,
            label: item.name,
          });
        });
        setFacilitiesData(dataObject);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoadingFacilities(false));
  };

  const removeImg = (img) => {
    const remaining = Array.from(multiFiles).filter(
      (item) => item.name !== img.name
    );
    setMultiFiles(remaining);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (Object.keys(multiFiles).length <= 0) {
      return toast.error('Select place image to continue');
    }
    if (Object.keys(categories).length <= 0) {
      return toast.error('Select a category to continue');
    }

    let CategoryData = [];
    let FacilitiesData = [];
    let promises = [];

    Array.from(multiFiles).forEach(async (item) => {
      promises.push(convertImageToBase64(item));
    });

    let photosInBase64 = await Promise.all(promises);

    categories.forEach((item) => {
      CategoryData.push(item.id);
    });

    facilities.forEach((item) => {
      FacilitiesData.push(item.id);
    });

    setLoading(true);

    const payload = {
      city_id: parseInt(cityId),
      name,
      photos: photosInBase64,
      categories: CategoryData,
      address,
      description,
      phoneNumber: phone,
      email,
      website,
      facebook,
      twitter,
      fromprice: fromPrice,
      toprice: toPrice,
      openingtime: openningTime,
      openningDay,
      checkin: checkIn,
      checkout,
      timespend: timeSpend,
      thingstonote: thingsToNote,
      longitude,
      latitude,
      starLevel: star,
      facilities: FacilitiesData,
      paidFacilities,
      sleepBookingUrl: booking,
      sleepAirbnbUrl: airbnb,
      sleepHostelWorldUrl: hostelWorld,
    };

    api()
      .post('/admin/places', payload)
      .then((res) => {
        setLoading(false);
        toast.success('Place added successfully');
        clearForm();
      })
      .catch((err) => {
        parseMessage(err);
        setLoading(false);
      });
  };

  const clearForm = () => {
    setName('');
    setAddress('');
    setAddress('');
    setLatitude('');
    setLongitude('');
    setPhone('');
    setFromPrice('');
    setToPrice('');
    setEmail('');
    setOpenningDay('');
    setOpenningTime('');
    setFaceBook('');
    setTwitter('');
    setWebsite('');
    setCheckin('');
    SetCheckout('');
    setStar('');
    setTwitter('');
    setTimeSpend('');
    setDescription('');
    setPaidFacilities('');
    setBooking('');
    setHostelWorld('');
    setAirbnb('');
    setThingsToNote('');
    setMultiFiles([]);
    selectRef?.current?.select?.clearValue();
  };

  useEffect(() => {
    getCities();
    getCategories();
    getFacilities();
  }, []);

  return (
    <AdminWrapper>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h5 className="card-title">Add place</h5>
          </div>
        </div>
        <div className="iq-card-body">
          <form onSubmit={submit}>
            <div className="row">
              <div className="col-lg-6">
                {loadingCities ? (
                  <Skeleton height={35} width={220} />
                ) : (
                  <div>
                    <div>
                      Select city{' '}
                      <span className="text-danger">*</span>
                    </div>
                    <select
                      className="form-control"
                      onChange={(e) => setCityId(e.target.value)}
                      value={cityId}
                    >
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                {loadingCategories ? (
                  <Skeleton height={35} width={220} />
                ) : (
                  <div>
                    <div>
                      Categories{' '}
                      <span className="text-danger">*</span>
                    </div>
                    <Select
                      options={categoryData}
                      onChange={(val) => setCategories(val)}
                      isMulti
                      isSearchable
                      ref={selectRef}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: Hoa Hong Hotel
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    required
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: 29 Hoang Dieu Street, Da Nang
                  </small>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">
                    Longitude <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="longitude"
                    required
                    className="form-control"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                  <small className="text-grey">Ex: 41.40338</small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">
                    Latitude <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="latitude"
                    required
                    className="form-control"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                  <small className="text-grey">Ex: 2.17403</small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Phone Number</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: +84 4 3984 4478
                  </small>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">From Price</label>
                  <input
                    type="text"
                    name="fromPrice"
                    className="form-control"
                    value={fromPrice}
                    onChange={(e) => setFromPrice(e.target.value)}
                  />
                  <small className="text-grey">Ex: 5</small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">To Price</label>
                  <input
                    type="text"
                    name="toPrice"
                    className="form-control"
                    value={toPrice}
                    onChange={(e) => setToPrice(e.target.value)}
                  />
                  <small className="text-grey">Ex: 20</small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: hello@domain.com
                  </small>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Opening Time</label>
                  <input
                    type="text"
                    name="openningTime"
                    className="form-control"
                    value={openningTime}
                    onChange={(e) => setOpenningTime(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: From 8:00 PM - 10:00 AM
                  </small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Opening Day</label>
                  <input
                    type="text"
                    name="openningDay"
                    className="form-control"
                    value={openningDay}
                    onChange={(e) => setOpenningDay(e.target.value)}
                  />
                  <small className="text-grey">Ex: Mon - Sat</small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Website</label>
                  <input
                    type="text"
                    name="website"
                    className="form-control"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                  <small className="text-grey">Ex: www.abc.com</small>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Check In</label>
                  <input
                    type="text"
                    name="checkIn"
                    className="form-control"
                    value={checkIn}
                    onChange={(e) => setCheckin(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: From 8:00 PM - 10:00 AM
                  </small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Check Out</label>
                  <input
                    type="text"
                    name="checkout"
                    className="form-control"
                    value={checkout}
                    onChange={(e) => SetCheckout(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex: From 8:00 PM - 10:00 AM
                  </small>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="name">Facebook</label>
                  <input
                    type="text"
                    name="facebook"
                    className="form-control"
                    value={facebook}
                    onChange={(e) => setFaceBook(e.target.value)}
                  />
                  <small className="text-grey">Ex: fb.com/name</small>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">
                    Star Level
                    <span className="text-danger">(Hotel Only)</span>
                  </label>
                  <input
                    type="text"
                    name="star"
                    className="form-control"
                    value={star}
                    onChange={(e) => setStar(e.target.value)}
                  />
                  <small className="text-grey">Ex: 3</small>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">Twitter</label>
                  <input
                    type="text"
                    name="twitter"
                    className="form-control"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                  <small className="text-grey">Ex: fb.com/name</small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="timeSpend">Time Spend</label>
                  <input
                    type="text"
                    className="form-control"
                    name="timeSpend"
                    value={timeSpend}
                    onChange={(e) => setTimeSpend(e.target.value)}
                  />
                  <small className="text-grey">
                    Time spend for this place. Ex: 3h
                  </small>
                </div>
              </div>

              <div className="col-md-6">
                {loadingFacilities ? (
                  <div className="form-group">
                    <div className="pt-4">
                      <Skeleton height={40} width={350} />
                    </div>
                  </div>
                ) : (
                  <div className="pt-2">
                    <div>Free Facilities</div>
                    <Select
                      options={facilitiesData}
                      isMulti
                      onChange={(e) => setFacilities(e)}
                      isSearchable
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="description">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  required
                  onChange={(e) => setDescription(e.target.value)}
                  className="textarea form-control"
                  rows="5"
                  value={description}
                ></textarea>
                <small className="text-grey">
                  Information about this place
                </small>
              </div>
              <div className="col-md-6">
                <label htmlFor="description">Paid Facilities</label>
                <textarea
                  onChange={(e) => setPaidFacilities(e.target.value)}
                  className="textarea form-control"
                  rows="5"
                  value={paidFacilities}
                ></textarea>
                <small className="text-grey">
                  Ex: @Wifi @ Bar@GYM @ Bike Rental
                </small>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-6">
                <label htmlFor="thingsToNote">Things to note</label>
                <textarea
                  onChange={(e) => setThingsToNote(e.target.value)}
                  className="textarea form-control"
                  rows="5"
                  value={thingsToNote}
                ></textarea>
                <small className="text-grey">
                  Ex: 1. Suitable for child 2. ***
                </small>
              </div>
            </div>

            <hr className="mt-5 mb-3" />
            <p className="text-danger">Book Sleep Only</p>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Booking.com</label>
                  <input
                    type="text"
                    name="booking"
                    className="form-control"
                    value={booking}
                    onChange={(e) => setBooking(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex:
                    https://www.booking.com/hotel/vn/sofitelmetropole.html...
                  </small>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">HostelWorld</label>
                  <input
                    type="text"
                    name="hostelWorld"
                    className="form-control"
                    value={hostelWorld}
                    onChange={(e) => setHostelWorld(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex:
                    https://www.booking.com/hotel/vn/sofitelmetropole.html...
                  </small>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="name">Airbnb</label>
                  <input
                    type="text"
                    name="hostelWorld"
                    className="form-control"
                    value={airbnb}
                    onChange={(e) => setAirbnb(e.target.value)}
                  />
                  <small className="text-grey">
                    Ex:
                    https://www.airbnb.com/vn/sofitelmetropole.html...
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-5 mb-3" />

            <div>
              <p>Images</p>
              <div className="image-upload-sheet">
                <div className="row no-gutters">
                  {Array.from(multiFiles).map((fileItem, index) => (
                    <div className="col-sm-3" key={index}>
                      <div onClick={() => removeImg(fileItem)}>
                        <span
                          className="iconify delete-img-icon"
                          data-icon="ant-design:close-circle-outlined"
                          style={{ color: 'red' }}
                        ></span>
                      </div>
                      <img
                        src={URL.createObjectURL(fileItem)}
                        style={{
                          width: '188px',
                          height: '185px',
                          marginTop: '4px',
                          marginBottom: '4px',
                          marginLeft: '2px',
                          borderRadius: '5px',
                        }}
                      />
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  id="photopicker"
                  multiple
                  style={{ display: 'none' }}
                  ref={pickerRef}
                  multiple
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => setMultiFiles(e.target.files)}
                />
              </div>

              <div
                className="picker-icon"
                onClick={() => pickerRef.current.click()}
              >
                <span
                  className="iconify"
                  data-icon="ic:baseline-upload-file"
                  data-inline="false"
                ></span>
              </div>
            </div>

            <hr className="mb-2 mt-0 pt-0" />
            <div className="text-center mt-4">
              {loading && (
                <>
                  <i
                    className="fas fa-circle-notch fa-spin"
                    style={{ color: '#F78567', marginRight: 20 }}
                  ></i>
                </>
              )}
              {!loading && (
                <>
                  <button
                    type="submit"
                    className="btn btn-md btn-primary loading m-2"
                  >
                    Save Changes
                  </button>
                </>
              )}
              <Link to="/places" className="btn btn-md btn-secondary">
                Dismiss
              </Link>
            </div>
          </form>
        </div>
      </div>
    </AdminWrapper>
  );
}
