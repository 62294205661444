import React, { useEffect, useState } from 'react';
import AdminWrapper from '../../components/common/AdminWrapper';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CountryModal from '../../components/modals/CountryModal';
import api from '../../api';
import { parseMessage } from '../../utility/Error';

export default () => {
  const [countries, setCountries] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteKey, setDeleteKey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [control, setControl] = useState('Add');
  const [countryData, setCountryData] = useState({});

  const onSearch = (val) => {
    setQuery(val);
    let text = val.toLowerCase();
    document.querySelectorAll('#search-item').forEach((dataRow) => {
      const name = dataRow.childNodes[1].textContent;
      const symbol = dataRow.childNodes[2].textContent;
      if (
        name.toLowerCase().indexOf(text) !== -1 ||
        symbol.toLowerCase().indexOf(text) !== -1
      ) {
        dataRow.style.display = 'table-row';
      } else {
        dataRow.style.display = 'none';
      }
    });
  };

  const getCountries = (showLoading) => {
    if (showLoading) {
      setLoading(true);
    }

    api()
      .get('/admin/country')
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoading(false));
  };

  const confirmDelete = (data) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCountry(data),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteCountry = (data) => {
    setDeleting(true);
    setDeleteKey(data.id);
    api()
      .delete(`/admin/country/${data.id}`)
      .then((res) => {
        setDeleteKey('');
        getCountries(false);
        toast.success('Country information deleted!');
      })
      .catch((err) => parseMessage(err))
      .finally(() => {
        setDeleting(false);
      });
  };

  const setEdit = (data) => {
    setCountryData(data);
    setControl('Edit');
    setIsOpen(true);
  };

  useEffect(() => {
    getCountries(true);
  }, []);

  let container;

  if (loading) {
    container = (
      <>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
      </>
    );
  } else {
    if (Object.keys(countries).length > 0) {
      container = countries.map((item, index) => (
        <tr key={index} id="search-item">
          <td>{index + 1}</td>
          <td>{item.name}</td>
          <td>{item.currencyName}</td>
          <td>{item.currencySymbol}</td>
          <td>
            {item.isDelete ? (
              <span className="badge badge-pill iq-bg-danger">
                Deleted
              </span>
            ) : (
              <span className="badge badge-pill iq-bg-success">
                Active
              </span>
            )}
          </td>
          <td>
            <i
              className="ri-ball-pen-fill text-success pr-1 action-badge-size"
              onClick={() => setEdit(item)}
            ></i>
            {deleteKey == item.id && deleting ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                <i
                  className="ri-delete-bin-5-line text-danger action-badge-size"
                  onClick={() => confirmDelete(item)}
                ></i>
              </>
            )}
          </td>
        </tr>
      ));
    } else {
      container = (
        <tr>
          <td></td>
          <td></td>
          <td>No information at the moment</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }

  return (
    <AdminWrapper>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Countries</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row mb-3">
            <div className="col-2 mr-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setIsOpen(true);
                  setCountryData({});
                }}
              >
                Add country
              </button>
            </div>
            <div className="col-3 ml-auto">
              <input
                className="form-control"
                defaultValue={query}
                type="text"
                placeholder="Search country..."
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Currency Name</th>
                <th scope="col">Currency Symbol</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{container}</tbody>
          </table>
        </div>
      </div>

      <CountryModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        countryData={countryData}
        control={control}
        fetchCountries={() => getCountries(false)}
      />
    </AdminWrapper>
  );
};
