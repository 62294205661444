import React, { useState, useEffect } from 'react';
// import { db } from "../../firebase";
import AdminWrapper from '../../components/common/AdminWrapper';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CityModal from '../../components/modals/CityModal';
import { parseMessage } from '../../utility/Error';
import api from '../../api';

export default () => {
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteKey, setDeleteKey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [control, setControl] = useState('Add');
  const [countryId, setCountryId] = useState('');
  const [cityData, setCityData] = useState({});

  const getCities = (showLoading) => {
    if (showLoading) {
      setLoading(true);
    }

    api()
      .get('/admin/country')
      .then((res) => {
        setCountries(res.data.data);
        setCountryId(res.data.data[0].id);
        getCitiesByCountry(res.data.data[0].id);
      })
      .catch((err) => {
        parseMessage(err);
        setLoading(false);
      });
  };

  const getCitiesByCountry = (countryId) => {
    api()
      .get(`/country/${countryId}/cities`)
      .then((res) => setCities(res.data))
      .catch((err) => parseMessage(err))
      .finally(() => setLoading(false));
  };

  const switchCountry = (id) => {
    setCountryId(id);
    setLoading(true);
    getCitiesByCountry(id);
  };

  const onSearch = (val) => {
    setQuery(val);
    let text = val.toLowerCase();
    document.querySelectorAll('#search-item').forEach((dataRow) => {
      const name = dataRow.childNodes[2].textContent;
      if (name.toLowerCase().indexOf(text) !== -1) {
        dataRow.style.display = 'table-row';
      } else {
        dataRow.style.display = 'none';
      }
    });
  };

  const removeDelete = (data) => {
    setDeleting(true);
    setDeleteKey(data.id);
    api()
      .patch(`/admin/city/${data.id}`, { status: true })
      .then((res) => {
        getCitiesByCountry(countryId);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setDeleting(false));
  };

  const confirmDelete = (data) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCity(data),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteCity = (data) => {
    setDeleting(true);
    setDeleteKey(data.id);
    api()
      .patch(`/admin/city/${data.id}`, { status: false })
      .then((res) => {
        getCitiesByCountry(countryId);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setDeleting(false));
  };

  const setEdit = (data) => {
    setCityData(data);
    setControl('Edit');
    setIsOpen(true);
  };

  useEffect(() => {
    getCities(true);
  }, []);

  let container;

  if (loading) {
    container = (
      <>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
      </>
    );
  } else {
    if (Object.keys(cities).length > 0) {
      container = cities.map((city, index) => (
        <tr key={index} id="search-item">
          <td>{index + 1}</td>
          <td>
            <img
              src={city.photo_url}
              style={{ width: 40, height: 30 }}
            />
          </td>
          <td>{city.name}</td>
          <td>{city.cafe_count}</td>
          <td>{city.restaurant_count}</td>
          <td>
            {!city.status ? (
              <span className="badge badge-pill iq-bg-danger">
                Deleted
              </span>
            ) : (
              <span className="badge badge-pill iq-bg-success">
                Active
              </span>
            )}
          </td>
          <td>
            <i
              className="ri-ball-pen-fill text-success pr-1 action-badge-size"
              onClick={() => setEdit(city)}
            ></i>
            {deleteKey == city.id && deleting ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                {!city.status ? (
                  <span onClick={() => removeDelete(city)}>
                    <span
                      className="iconify text-secondary action-badge-size"
                      data-icon="ic:twotone-settings-backup-restore"
                      data-inline="false"
                    ></span>
                  </span>
                ) : (
                  <i
                    className="ri-delete-bin-5-line text-danger action-badge-size"
                    onClick={() => confirmDelete(city)}
                  ></i>
                )}
              </>
            )}
          </td>
        </tr>
      ));
    } else {
      container = (
        <tr>
          <td colSpan={7} className="text-center">
            No data found
          </td>
        </tr>
      );
    }
  }

  return (
    <AdminWrapper>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Cities</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row mb-3">
            <div className="col-2 mr-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setIsOpen(true);
                  setCityData({});
                  setControl('Add');
                }}
              >
                Add city
              </button>
            </div>
            <div className="col-4"></div>
            <div className="col-3">
              {Object.keys(countries).length > 0 && (
                <select
                  className="form-control select-control"
                  value={countryId}
                  onChange={(e) => switchCountry(e.target.value)}
                >
                  {countries.map((country, index) => (
                    <option value={country.id} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="col-3 ml-auto">
              <input
                className="form-control"
                defaultValue={query}
                type="text"
                placeholder="Search city..."
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                <th scope="col">Cafes</th>
                <th scope="col">Restaurants</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>{container}</tbody>
          </table>
        </div>
      </div>

      <CityModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        cityData={cityData}
        control={control}
        fetchCities={() => getCitiesByCountry(countryId)}
        defaultCountryId={countryId}
      />
    </AdminWrapper>
  );
};
