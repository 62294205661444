import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import AdminWrapper from '../../components/common/AdminWrapper';

export default function Dashboard() {
  const [stats, setStats] = useState({
    users: 0,
    cities: 0,
    comments: 0,
    nologTokens: 0,
  });
  const [comments, setComments] = useState([]);
  const [places, setPlaces] = useState(0);
  const [userTokens, setUserTokens] = useState(0);
  const [cities, setCities] = useState([]);
  // const [userPer]
  const [fetchingCities, setFetchingCities] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPlaces, setLoadingPlaces] = useState(false);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [fetchingUserTokens, setFetchingUserTokens] = useState(false);

  const sortCityAnalysis = (data) => {
    // console.log(data)
    // let items = []
    // cities.forEach(city => {
    //   data.forEach(user => {
    //     if(user.cityId === city.key){
    //       item
    //     }
    //   })
    // })
    // let jeddahSum = 0;
    // let dubaiSum = 0;
    // data.forEach((item) => {
    //   if (item.cityId === "-LddfwRSydoVjPjXKAUa") {
    //     jeddahSum = jeddahSum + 1;
    //   }
    //   if (item.cityId === "-LdYOG-6AyIBOgocsUag") {
    //     dubaiSum = dubaiSum + 1;
    //   }
    // });
  };

  // const getCities = () => {
  //   setFetchingCities(true);
  //   db.ref('/city/-KSQODMwVG3GM-6cmys4')
  //     .once('value')
  //     .then((snapshot) => {
  //       const dataObject = [];
  //       const dataItems = [];
  //       const data = snapshot.val() ? snapshot.val() : {};
  //       const keyEntries = Object.keys(data);
  //       const dataEntries = Object.entries(data);
  //       dataEntries.forEach((data) => {
  //         data.forEach((item) => {
  //           if (typeof item == 'object') {
  //             dataItems.push(item);
  //           }
  //         });
  //       });
  //       keyEntries.forEach((keyItem, index) => {
  //         let newData = dataItems[index];
  //         newData.key = keyItem;
  //         dataObject.push(newData);
  //       });

  //       setCities(dataObject);
  //     })
  //     .catch((err) => {})
  //     .finally(() => setFetchingCities(false));
  // };

  // const getDetails = () => {
  //   setLoading(true);
  //   const users = db.ref('users');
  //   const cities = db.ref('city/-KSQODMwVG3GM-6cmys4');
  //   const comments = db.ref('place_comment');
  //   const noLogTokens = db.ref('nologtokens');

  //   let userPromise = users.once('value');
  //   let cityPromise = cities.once('value');
  //   let commentPromise = comments.once('value');
  //   let nologtokenPromise = noLogTokens.once('value');

  //   Promise.all([
  //     userPromise,
  //     cityPromise,
  //     commentPromise,
  //     nologtokenPromise,
  //   ])
  //     .then((snapshot) => {
  //       let t = [];
  //       snapshot.forEach((child) => {
  //         let size = Object.keys(child.val()).length;
  //         t.push(size);
  //       });
  //       setStats({
  //         users: t[0],
  //         cities: t[1],
  //         comments: t[2],
  //         nologTokens: t[3],
  //       });
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));
  // };

  // const getPlaces = () => {
  //   setLoadingPlaces(true);
  //   const cities = db.ref('city/-KSQODMwVG3GM-6cmys4');
  //   const place = db.ref('place');
  //   let promises = [];

  //   cities
  //     .once('value')
  //     .then((snapshot) => {
  //       snapshot.forEach((child) => {
  //         let promise = place.child(child.key).once('value');
  //         promises.push(promise);
  //       });

  //       Promise.all(promises)
  //         .then((placeSnapshots) => {
  //           let places = 0;
  //           placeSnapshots.forEach((placeSnapshot) => {
  //             places =
  //               Object.keys(placeSnapshot.val()).length + places;
  //           });
  //           setPlaces(places);
  //         })
  //         .catch((err) => console.log(err));
  //     })
  //     .finally(() => setLoadingPlaces(false));
  // };

  // const getComments = () => {
  //   setFetchingComments(true);
  //   const comments = db.ref('place_comment');
  //   let promises = [];

  //   comments
  //     .limitToLast(5)
  //     .once('value')
  //     .then((snapshot) => {
  //       const dataObject = [];
  //       const dataItems = [];
  //       const data = snapshot.val() ? snapshot.val() : {};
  //       const keyEntries = Object.keys(data);
  //       const dataEntries = Object.entries(data);

  //       dataEntries.forEach((data) => {
  //         data.forEach((item) => {
  //           if (typeof item == 'object') {
  //             dataItems.push(item);
  //           }
  //         });
  //       });

  //       keyEntries.forEach((keyItem, index) => {
  //         let newData = dataItems[index];
  //         newData.key = keyItem;
  //         let promise = db
  //           .ref(`place/${newData.cityId}`)
  //           .child(newData.placeId)
  //           .once('value');
  //         promises.push(promise);
  //         dataObject.push(newData);
  //       });

  //       Promise.all(promises)
  //         .then((snapshots) => {
  //           let dataSet = [];
  //           snapshots.forEach((child, index) => {
  //             dataSet.push({ ...child.val(), ...dataObject[index] });
  //           });
  //           setComments(dataSet);
  //         })
  //         .catch(() => {});
  //     })
  //     .finally(() => setFetchingComments(false));
  // };

  // const getLoggedTokens = () => {
  //   setFetchingUserTokens(true);
  //   let sum = 0;
  //   let userAndTokens = [];
  //   db.ref('users')
  //     .orderByChild('pushToken')
  //     .once('value')
  //     .then((snapshot) => {
  //       snapshot.forEach((child) => {
  //         if (
  //           child.val().pushToken &&
  //           child.val().pushToken !== undefined &&
  //           child.val().pushToken !== ''
  //         ) {
  //           sum = sum + 1;
  //         }
  //         if (child.val().cityId) {
  //           userAndTokens.push({ ...child.val() });
  //         }
  //       });
  //       setUserTokens(sum);
  //       sortCityAnalysis(userAndTokens);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setFetchingUserTokens(false));
  // };

  let commentsContainer;

  if (fetchingComments) {
    commentsContainer = (
      <>
        <li>
          <div className="timeline-dots"></div>
          <h6 className="float-left mb-1">
            <Skeleton width={40} />
          </h6>
          <div className="d-inline-block w-100">
            <p className="mb-0">
              <Skeleton width={100} />
            </p>
          </div>
        </li>
        <li>
          <div className="timeline-dots"></div>
          <h6 className="float-left mb-1">
            <Skeleton width={40} />
          </h6>
          <div className="d-inline-block w-100">
            <p className="mb-0">
              <Skeleton width={100} />
            </p>
          </div>
        </li>
        <li>
          <div className="timeline-dots"></div>
          <h6 className="float-left mb-1">
            <Skeleton width={40} />
          </h6>
          <div className="d-inline-block w-100">
            <p className="mb-0">
              <Skeleton width={100} />
            </p>
          </div>
        </li>
      </>
    );
  } else {
    if (Object.keys(comments).length > 0) {
      commentsContainer = comments.map((comment) => (
        <li key={comment.key}>
          <div className="timeline-dots"></div>
          <h6 className="float-left mb-1">{comment.name}</h6>
          <div className="d-inline-block w-100">
            <p className="mb-0">{comment.comment}</p>
          </div>
        </li>
      ));
    }
  }

  useEffect(() => {
    // getDetails();
    // getCities();
    // getPlaces();
    // getComments();
    // getLoggedTokens();
  }, []);

  return (
    <AdminWrapper>
      <div className="row">
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch">
            <div className="iq-card-body">
              <div className="d-flex align-items-center">
                <div className="icon iq-icon-box rounded iq-bg-primary mr-3">
                  <span
                    className="iconify"
                    data-icon="icomoon-free:users"
                    data-inline="false"
                    style={{ fontSize: '20px' }}
                  ></span>
                </div>
                <div className="iq-details col-sm-9 p-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="title text-dark">Users</span>
                  </div>
                  <span className="">
                    {loading ? <Skeleton width={40} /> : stats.users}
                  </span>
                  <div className="iq-progress-bar-linear d-inline-block w-100">
                    <div className="iq-progress-bar">
                      <span
                        className="bg-primary"
                        data-percent="32"
                        style={{
                          transition: 'width 2s ease 0s',
                          width: '32',
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="d-flex align-items-center">
                <div className="icon iq-icon-box rounded iq-bg-success mr-3">
                  <span
                    className="iconify"
                    data-icon="emojione-monotone:world-map"
                    data-inline="false"
                    style={{ fontSize: '20px' }}
                  ></span>
                </div>
                <div className="iq-details col-sm-9 p-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="title text-dark">Cities</span>
                    <div className="text-success"></div>
                  </div>
                  <span className="">
                    {loading ? <Skeleton width={40} /> : stats.cities}
                  </span>
                  <div className="iq-progress-bar-linear d-inline-block w-100">
                    <div className="iq-progress-bar">
                      <span
                        className="bg-success"
                        data-percent="20"
                        style={{
                          transition: 'width 2s ease 0s',
                          width: '20',
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between"></div>
                </div>
              </div>
              <hr className="mt-2 mb-2" />
              <div className="d-flex align-items-center">
                <div className="icon iq-icon-box rounded iq-bg-info mr-3">
                  <span
                    className="iconify"
                    data-icon="fluent:globe-location-20-filled"
                    data-inline="false"
                  ></span>
                </div>
                <div className="iq-details col-sm-9 p-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="title text-dark">Places</span>
                    <div className="text-info"></div>
                  </div>
                  <span className="">
                    {loadingPlaces ? <Skeleton width={40} /> : places}
                  </span>
                  <div className="iq-progress-bar-linear d-inline-block w-100">
                    <div className="iq-progress-bar">
                      <span
                        className="bg-info"
                        data-percent="60"
                        style={{
                          transition: 'width 2s ease 0s',
                          width: '60%',
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-12">
              <div className="iq-card iq-card-block iq-card-stretch mb-3">
                <div className="iq-card-body p-3">
                  <div className="d-flex align-items-center">
                    <div className="user-img img-fluid comment-circle">
                      <span
                        className="iconify"
                        data-icon="uil:comment-image"
                        data-inline="false"
                        style={{ fontSize: '25px' }}
                      ></span>
                    </div>
                    <div className="media-support-info ml-3">
                      <h6>Comments</h6>
                    </div>
                    <div className="iq-card-header-toolbar">
                      {loading ? (
                        <Skeleton width={40} />
                      ) : (
                        <div className="badge badge-pill badge-success">
                          {stats.comments}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="iq-card iq-card-block iq-card-stretch mb-3">
                <div className="iq-card-body p-3">
                  <div className="text-center justify-content-center">
                    <div className="iq-header-title">
                      <h4 className="card-title mb-5">
                        Push Notifications Tokens
                      </h4>
                    </div>
                    <div className="">
                      <span
                        className="iconify phone-circle"
                        data-icon="bi:phone"
                        data-inline="false"
                        style={{ fontSize: '100px' }}
                      ></span>
                    </div>
                    <div className="mt-5">
                      <p>
                        No login users:{' '}
                        {loading ? (
                          <Skeleton width={40} />
                        ) : (
                          `${stats.nologTokens}`
                        )}{' '}
                      </p>
                    </div>
                    <div className="mt-5">
                      <p>
                        Logged users:{' '}
                        {fetchingUserTokens ? (
                          <Skeleton width={40} />
                        ) : (
                          `${userTokens}`
                        )}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between border-bottom">
              <div className="iq-header-title">
                <h4 className="card-title">Recent Comments</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <ul className="iq-timeline">{commentsContainer}</ul>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
}
