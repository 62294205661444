import { toast } from 'react-toastify';

export const parseMessage = (error) => {
  if (error.response) {
    const data = error.response.data;

    if (data.errors) {
      const arr = Object.keys(data.errors);
      if (
        Object.values(data.errors) !== undefined &&
        Object.values(data.errors)[0] !== undefined &&
        Object.values(data.errors)[0][0] !== undefined
      ) {
        return toast.error(Object.values(data.errors)[0][0]);
      } else {
        return toast.error(arr[0]);
      }
    }
    if (data.error && !Object.keys(data.error)) {
      if (data.error?.error != undefined) {
        return toast.error(data.error.error);
      } else {
        return toast.error(data.error);
      }
    }

    if (
      data.error &&
      Object.keys(data.error) &&
      typeof data.error == 'string'
    ) {
      if (data.error?.error != undefined) {
        return toast.error(data.error.error);
      } else {
        return toast.error(data.error);
      }
    }

    if (data.message) {
      return toast.error(data.message);
    }
    if (error && typeof error == 'string') {
      return toast.error(error);
    }
  } else {
    if (error.message) {
      return toast.error(error.message);
    }
    if (error.code === 'ECONNABORTED') {
      return toast.error('Check your internet connection');
    }
  }
};
