import axios from 'axios';
import { toast } from 'react-toastify';

const baseURL = process.env.REACT_APP_BASEURL;

export default (tkn) => {
  const localTkn = localStorage.getItem('WHERETO_DASH_TOKEN_v2');
  const token = JSON.parse(localTkn) || tkn;

  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      // if (error.response.status === 401 && !originalRequest._retry) {
      //   toast.error('Sessioin expired!');
      // }
      return Promise.reject(error);
    }
  );
  return instance;
};
