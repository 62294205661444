import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import AdminWrapper from '../../components/common/AdminWrapper';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from '../../api';
import { parseMessage } from '../../utility/Error';
import { convertImageToBase64, fixCategories } from '../../utility';

export default function EditPlace() {
  const params = useParams();
  const history = useHistory();
  const { cityKey, placeKey } = params;
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState({});

  const pickerRef = useRef();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [phone, setPhone] = useState('');
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [email, setEmail] = useState('');
  const [openningTime, setOpenningTime] = useState('');
  const [openningDay, setOpenningDay] = useState('');
  const [website, setWebsite] = useState('');
  const [checkIn, setCheckin] = useState('');
  const [checkout, SetCheckout] = useState('');
  const [facebook, setFaceBook] = useState('');
  const [star, setStar] = useState('');
  const [twitter, setTwitter] = useState('');
  const [timeSpend, setTimeSpend] = useState('');
  const [description, setDescription] = useState('');
  const [paidFacilities, setPaidFacilities] = useState('');
  const [booking, setBooking] = useState('');
  const [hostelWorld, setHostelWorld] = useState('');
  const [airbnb, setAirbnb] = useState('');
  const [thingsToNote, setThingsToNote] = useState('');

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFreeFacilities, setSelectedFreeFacilities] =
    useState([]);

  const [existingPhotos, setExistingPhotos] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [freeFacilities, setFreeFacilities] = useState([]);
  const [cityId, setCityId] = useState('');
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deletingPhoto, setDeletingPhoto] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const getPlace = (enableLoading) => {
    if (enableLoading) {
      setLoading(true);
    }

    api()
      .get(`/places/${placeKey}`)
      .then((res) => {
        setPlace(res.data);
        prefillForm();
        setExistingPhotos(res.data.photos);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoading(false));
  };

  const getCities = () => {
    setLoading(true);
    api()
      .get('/admin/city')
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoading(false));
  };

  const getCategories = () => {
    setLoadingCategories(true);
    api()
      .get('/categories')
      .then((res) => {
        let dataObject = [];
        res.data.forEach((item) => {
          dataObject.push({
            id: item.id,
            value: item.id,
            label: item.name,
          });
        });
        setCategories(dataObject);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoadingCategories(false));
  };

  const getFacilities = () => {
    setLoadingFacilities(true);
    api()
      .get('/admin/facilities')
      .then((res) => {
        let dataObject = [];
        res.data.forEach((item) => {
          dataObject.push({
            id: item.id,
            value: item.id,
            label: item.name,
          });
        });
        setFreeFacilities(dataObject);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoadingFacilities(false));
  };

  const removePhoto = (id) => {
    setDeleteId(id);
    setDeletingPhoto(true);
    api()
      .delete(`/admin/placePhotos/${id}`)
      .then((res) => {
        toast.success('Photo deleted successfully');
        getPlace(false);
      })
      .catch((err) => parseMessage(err))
      .finally(() => {
        setDeletingPhoto(false);
      });
  };

  const uploadPhoto = async (files) => {
    setUploading(true);
    try {
      let promises = [];
      let conversionPromises = [];

      Array.from(files).forEach((item) => {
        conversionPromises.push(convertImageToBase64(item));
      });

      let convertedFiles = await Promise.all(conversionPromises);

      convertedFiles.forEach((item) => {
        const payload = {
          photo_url: item,
          place_id: placeKey,
        };
        promises.push(api().post('/admin/placePhotos', payload));
      });

      await Promise.all(promises);
      setUploading(false);
      getPlace(false);
    } catch (error) {
      parseMessage(error);
      setUploading(false);
    }
  };

  const prefillForm = () => {
    if (Object.keys(place).length > 0) {
      setName(place.name);
      setAddress(place.address);
      setLatitude(place.latitude);
      setLongitude(place.longitude);
      setPhone(place.phoneNumber);
      setFromPrice(place.fromPrice);
      setToPrice(place.toprice);
      setEmail(place.email);
      setOpenningTime(place.openingTime);
      setOpenningDay(place.openingDay);
      setWebsite(place.website);
      setCheckin(place.checkin);
      SetCheckout(place.checkout);
      setFaceBook(place.facebook);
      setStar(place.starLevel);
      setTwitter(place.twitter);
      setTimeSpend(place.timespend);
      setSelectedCategories(place.categories);
      setSelectedFreeFacilities(place.facilities);
      setDescription(place.description);
      setPaidFacilities(place.paidFacilities);
      setThingsToNote(place.thingstonote);
      setBooking(place.sleepBookingUrl);
      setHostelWorld(place.sleepHostelWorldUrl);
      setAirbnb(place.sleepAirbnbUrl);
      setExistingPhotos(place.photos);
      setCityId(cityKey);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(selectedCategories).length <= 0) {
      return toast.error('Select a category to continue');
    }

    setUpdating(true);

    let CategoryData = [];
    let FacilitiesData = [];

    selectedCategories.forEach((item) => {
      CategoryData.push(item.id);
    });

    selectedFreeFacilities.forEach((item) => {
      FacilitiesData.push(item.id);
    });

    const city_id = parseInt(cityId);

    const payload = {
      name,
      city_id,
      categories: CategoryData,
      address,
      description,
      phoneNumber: phone,
      email,
      website,
      facebook,
      twitter,
      fromprice: fromPrice,
      toprice: toPrice,
      openingtime: openningTime,
      openingDay: openningDay,
      checkin: checkIn,
      checkout,
      timespend: timeSpend,
      thingstonote: thingsToNote,
      longitude,
      latitude,
      starLevel: star,
      facilities: FacilitiesData,
      paidFacilities,
      sleepBookingUrl: booking,
      sleepAirbnbUrl: airbnb,
      sleepHostelWorldUrl: hostelWorld,
    };

    api()
      .patch(`/admin/places/${placeKey}`, payload)
      .then((res) => {
        setUpdating(false);
        toast.success('Place updated successfully');
        history.goBack();
      })
      .catch((err) => {
        parseMessage(err);
        setUpdating(false);
      });
  };

  let container;
  if (loading || loadingCategories) {
    container = (
      <div className="text-center pt-5 mt-5">
        <i
          className="fas fa-circle-notch fa-spin"
          style={{ color: '#F78567', fontSize: '45px' }}
        ></i>
      </div>
    );
  } else {
    if (Object.keys(place).length > 0) {
      container = (
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h5 className="card-title">Edit {place.name}</h5>
            </div>
          </div>
          <div className="iq-card-body">
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  {loadingCities ? (
                    <Skeleton height={35} width={220} />
                  ) : (
                    <div>
                      <div>
                        City <span className="text-danger">*</span>
                      </div>
                      <select
                        className="form-control"
                        onChange={(e) => setCityId(e.target.value)}
                        value={cityId}
                      >
                        {cities.map((city, index) => (
                          <option key={index} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  {loadingCategories ||
                  selectedCategories == undefined ||
                  Object.keys(selectedCategories).length == 0 ? (
                    <Skeleton height={35} width={220} />
                  ) : (
                    <div>
                      <div>
                        Categories{' '}
                        <span className="text-danger">*</span>
                      </div>
                      <Select
                        options={categories}
                        onChange={(val) => setSelectedCategories(val)}
                        isMulti
                        isSearchable
                        defaultValue={fixCategories(
                          categories,
                          selectedCategories
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      className="form-control"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: Hoa Hong Hotel
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="name">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      required
                      className="form-control"
                      defaultValue={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: 29 Hoang Dieu Street, Da Nang
                    </small>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      Longitude <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="longitude"
                      required
                      className="form-control"
                      defaultValue={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                    <small className="text-grey">Ex: 41.40338</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">
                      Latitude <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="latitude"
                      required
                      className="form-control"
                      defaultValue={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                    <small className="text-grey">Ex: 2.17403</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Phone Number</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      defaultValue={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: +84 4 3984 4478
                    </small>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">From Price</label>
                    <input
                      type="text"
                      name="fromPrice"
                      className="form-control"
                      defaultValue={fromPrice}
                      onChange={(e) => setFromPrice(e.target.value)}
                    />
                    <small className="text-grey">Ex: 5</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">To Price</label>
                    <input
                      type="text"
                      name="toPrice"
                      className="form-control"
                      defaultValue={toPrice}
                      onChange={(e) => setToPrice(e.target.value)}
                    />
                    <small className="text-grey">Ex: 20</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: hello@domain.com
                    </small>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Opening Time</label>
                    <input
                      type="text"
                      name="openningTime"
                      className="form-control"
                      defaultValue={openningTime}
                      onChange={(e) =>
                        setOpenningTime(e.target.value)
                      }
                    />
                    <small className="text-grey">
                      Ex: From 8:00 PM - 10:00 AM
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Opening Day</label>
                    <input
                      type="text"
                      name="openningDay"
                      className="form-control"
                      defaultValue={openningDay}
                      onChange={(e) => setOpenningDay(e.target.value)}
                    />
                    <small className="text-grey">Ex: Mon - Sat</small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Website</label>
                    <input
                      type="text"
                      name="website"
                      className="form-control"
                      defaultValue={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: www.abc.com
                    </small>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Check In</label>
                    <input
                      type="text"
                      name="checkIn"
                      className="form-control"
                      defaultValue={checkIn}
                      onChange={(e) => setCheckin(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: From 8:00 PM - 10:00 AM
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Check Out</label>
                    <input
                      type="text"
                      name="checkout"
                      className="form-control"
                      defaultValue={checkout}
                      onChange={(e) => SetCheckout(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: From 8:00 PM - 10:00 AM
                    </small>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="name">Facebook</label>
                    <input
                      type="text"
                      name="facebook"
                      className="form-control"
                      defaultValue={facebook}
                      onChange={(e) => setFaceBook(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: fb.com/name
                    </small>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="name">
                      Star Level
                      <span className="text-danger">
                        (Hotel Only)
                      </span>
                    </label>
                    <input
                      type="text"
                      name="star"
                      className="form-control"
                      defaultValue={star}
                      onChange={(e) => setStar(e.target.value)}
                    />
                    <small className="text-grey">Ex: 3</small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="name">Twitter</label>
                    <input
                      type="text"
                      name="twitter"
                      className="form-control"
                      defaultValue={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex: fb.com/name
                    </small>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="timeSpend">Time Spend</label>
                    <input
                      type="text"
                      className="form-control"
                      name="timeSpend"
                      defaultValue={timeSpend}
                      onChange={(e) => setTimeSpend(e.target.value)}
                    />
                    <small className="text-grey">
                      Time spend for this place. Ex: 3h
                    </small>
                  </div>
                </div>

                <div className="col-md-6">
                  {loadingFacilities ? (
                    <div className="form-group">
                      <div className="pt-4">
                        <Skeleton height={40} width={350} />
                      </div>
                    </div>
                  ) : (
                    <div className="pt-2">
                      <div>Free Facilities</div>
                      <Select
                        options={freeFacilities}
                        isMulti
                        onChange={(e) => setSelectedFreeFacilities(e)}
                        isSearchable
                        defaultValue={fixCategories(
                          freeFacilities,
                          place.facilities
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="description">
                    Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    required
                    onChange={(e) => setDescription(e.target.value)}
                    className="textarea form-control"
                    rows="5"
                    defaultValue={description}
                  ></textarea>
                  <small className="text-grey">
                    Information this place
                  </small>
                </div>
                <div className="col-md-6">
                  <label htmlFor="description">Paid Facilities</label>
                  <textarea
                    onChange={(e) =>
                      setPaidFacilities(e.target.value)
                    }
                    className="textarea form-control"
                    rows="5"
                    defaultValue={paidFacilities}
                  ></textarea>
                  <small className="text-grey">
                    Ex: @Wifi @ Bar@GYM @ Bike Rental
                  </small>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <label htmlFor="thingsToNote">Things to note</label>
                  <textarea
                    onChange={(e) => setThingsToNote(e.target.value)}
                    className="textarea form-control"
                    rows="5"
                    defaultValue={thingsToNote}
                  ></textarea>
                  <small className="text-grey">
                    Ex: 1. Suitable for child 2. ***
                  </small>
                </div>
              </div>

              <hr className="mt-5 mb-3" />
              <p className="text-danger">Book Sleep Only</p>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Booking.com</label>
                    <input
                      type="text"
                      name="booking"
                      className="form-control"
                      defaultValue={booking}
                      onChange={(e) => setBooking(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex:
                      https://www.booking.com/hotel/vn/sofitelmetropole.html...
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">HostelWorld</label>
                    <input
                      type="text"
                      name="hostelWorld"
                      className="form-control"
                      defaultValue={hostelWorld}
                      onChange={(e) => setHostelWorld(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex:
                      https://www.booking.com/hotel/vn/sofitelmetropole.html...
                    </small>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Airbnb</label>
                    <input
                      type="text"
                      name="hostelWorld"
                      className="form-control"
                      defaultValue={airbnb}
                      onChange={(e) => setAirbnb(e.target.value)}
                    />
                    <small className="text-grey">
                      Ex:
                      https://www.airbnb.com/vn/sofitelmetropole.html...
                    </small>
                  </div>
                </div>
              </div>
              <hr className="mt-5 mb-3" />

              <div>
                <p>Images</p>
                <div className="image-upload-sheet mb-2">
                  <div className="row no-gutters">
                    {existingPhotos.map((fileItem, index) => (
                      <div className="col-sm-3" key={index}>
                        <img
                          src={fileItem.url}
                          style={{
                            width: '188px',
                            height: '185px',
                            marginTop: '4px',
                            marginLeft: '2px',
                          }}
                        />
                        <div>
                          {deletingPhoto && deleteId == fileItem.id && (
                            <div
                              className="text-center"
                              style={{
                                marginLeft: '-40px',
                                marginTop: '15px',
                              }}
                            >
                              <i className="fa fa-spinner fa-spin" />
                            </div>
                          )}
                          {!deletingPhoto && deleteId != fileItem.id && (
                            <div
                              className="pl-5 ml-4"
                              onClick={() => removePhoto(fileItem.id)}
                            >
                              <span
                                className="iconify text-danger ml-3"
                                data-icon="bx:bxs-trash-alt"
                                data-inline="false"
                                style={{ fontSize: '25px' }}
                              ></span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="image-upload-sheet"
                  onClick={() => pickerRef.current.click()}
                >
                  {/* <div className="row no-gutters">
                    {Array.from(multiFiles).map((fileItem, index) => (
                      <div className="col-sm-3" key={index}>
                        <img
                          src={URL.createObjectURL(fileItem)}
                          style={{
                            width: '188px',
                            height: '185px',
                            marginTop: '4px',
                            marginLeft: '2px',
                          }}
                        />
                      </div>
                    ))}
                  </div> */}
                  <input
                    type="file"
                    id="photopicker"
                    multiple
                    style={{ display: 'none' }}
                    ref={pickerRef}
                    multiple
                    accept="image/x-png,image/jpeg"
                    onChange={(e) => uploadPhoto(e.target.files)}
                  />
                  {/* <small className="text-info">
                    {uploadMultipleStatus}
                  </small> */}
                </div>

                <div
                  className="picker-icon"
                  onClick={() => pickerRef.current.click()}
                >
                  <span
                    className="iconify"
                    data-icon="ic:baseline-upload-file"
                    data-inline="false"
                  ></span>
                </div>
              </div>

              <hr className="mb-2 mt-0 pt-0" />
              <div className="text-center mt-4">
                {updating && (
                  <>
                    <i
                      className="fas fa-circle-notch fa-spin"
                      style={{ color: '#F78567', marginRight: 20 }}
                    ></i>
                  </>
                )}
                {uploading && (
                  <>
                    <i
                      className="fas fa-circle-notch fa-spin"
                      style={{ color: '#F78567', marginRight: 20 }}
                    ></i>
                  </>
                )}
                {!updating && !uploading && (
                  <>
                    <button
                      type="submit"
                      className="btn btn-md btn-primary loading m-2"
                    >
                      Save Changes
                    </button>
                  </>
                )}
                <Link
                  to="/places"
                  className="btn btn-md btn-secondary"
                >
                  Dismiss
                </Link>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    getPlace(true);
    getCities();
    getCategories();
    getFacilities();
  }, []);

  useEffect(() => {
    prefillForm();
  }, [place]);

  return (
    <AdminWrapper>
      <div className="iq-card-body">{container}</div>
    </AdminWrapper>
  );
}
