import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function Header() {
  const history = useHistory();
  let user = localStorage.getItem('WHERETO_DASH_USER_v2');
  user = JSON.parse(user);

  const signOut = async () => {
    try {
      localStorage.removeItem('WHERETO_DASH_TOKEN_v2');
      localStorage.removeItem('WHERETO_DASH_USER_v2');
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="iq-top-navbar">
      <div className="iq-navbar-custom">
        <div className="iq-sidebar-logo">
          <div className="top-logo">
            <Link to="/dashboard" className="logo">
              <div className="iq-light-logo">
                <img
                  src="https://res.cloudinary.com/djnhrvjyf/image/upload/v1619705270/logo_inblwi.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="iq-dark-logo">
                <img
                  src="https://res.cloudinary.com/djnhrvjyf/image/upload/v1619705270/logo_inblwi.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <span>Wheretogo</span>
            </Link>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="navbar-left">
            <div className="iq-search-bar"></div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <i className="ri-menu-3-line"></i>
          </button>
          {/* <div className="iq-menu-bt align-self-center"></div> */}
          <ul className="navbar-list ml-auto">
            <li>
              <a
                href="#"
                className="
              search-toggle
              iq-waves-effect
              d-flex
              align-items-center
              bg-primary
              rounded
            "
              >
                <img
                  src={user.avatar_url}
                  className="img-fluid rounded mr-3"
                  alt="user"
                />
                <div className="caption">
                  <h6 className="mb-0 line-height text-white">
                    {user.name}
                  </h6>
                </div>
              </a>
              <div className="iq-sub-dropdown iq-user-dropdown">
                <div className="iq-card shadow-none m-0">
                  <div className="iq-card-body p-0">
                    <div className="bg-primary p-3">
                      <h5 className="mb-0 text-white line-height">
                        Hello {user.name}
                      </h5>
                    </div>
                    <a
                      href="profile.html"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-primary">
                          <i className="ri-file-user-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0">My Profile</h6>
                          <p className="mb-0 font-size-12">
                            View personal profile details.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="profile-edit.html"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-primary">
                          <i className="ri-profile-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0">Edit Profile</h6>
                          <p className="mb-0 font-size-12">
                            Modify your personal details.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="account-setting.html"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-primary">
                          <i className="ri-account-box-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0">Account settings</h6>
                          <p className="mb-0 font-size-12">
                            Manage your account parameters.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="privacy-setting.html"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center">
                        <div className="rounded iq-card-icon iq-bg-primary">
                          <i className="ri-lock-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0">Privacy Settings</h6>
                          <p className="mb-0 font-size-12">
                            Control your privacy parameters.
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="d-inline-block w-100 text-center p-3">
                      <span
                        className="btn btn-primary"
                        role="button"
                        onClick={() => signOut()}
                      >
                        Sign out
                        <i className="ri-login-box-line ml-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
