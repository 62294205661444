// import React, { useState } from "react";

// export default function Pagination({
//   data,
//   dataLimit,
//   pageLimit,
//   currentPage,
//   setCurrentPage,
// }) {
//   const [pages] = useState(Math.round(data.length / dataLimit));

//   function goToNextPage() {
//     setCurrentPage((page) => page + 1);
//   }

//   function goToPreviousPage() {
//     setCurrentPage((page) => page - 1);
//   }

//   function changePage(event) {
//     const pageNumber = Number(event.target.textContent);
//     setCurrentPage(pageNumber);
//   }

//   const getPaginationGroup = () => {
//     let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
//     return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
//   };

//   return (
//     <div className="pagination">
//       <button
//         onClick={goToPreviousPage}
//         className={`prev ${currentPage === 1 ? "disabled" : ""}`}
//       >
//         prev
//       </button>
//       {getPaginationGroup().map((item, index) => (
//         <button
//           key={index}
//           onClick={changePage}
//           className={`paginationItem ${
//             currentPage === item ? "active" : "paginate_button page-item"
//           }`}
//         >
//           <span>{item}</span>
//         </button>
//       ))}

//       <button
//         onClick={goToNextPage}
//         className={`next ${currentPage === pages ? "disabled" : ""}`}
//       >
//         next
//       </button>
//     </div>
//   );
// }

import React from 'react';

export default function index({
  prevPage,
  gotoNext,
  paginatedPageCount,
  gotoPage,
  currentPage,
}) {
  return (
    <nav
      aria-label="Page navigation example"
      style={{
        // width: '500px',
        overflowX: 'scroll',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      <ul className="pagination ">
        <li className="page-item lb-page">
          <a
            className={`page-link pointer ${
              currentPage === 1 ? 'disabled' : ''
            }`}
            onClick={() => prevPage()}
          >
            Previous
          </a>
        </li>

        {new Array(paginatedPageCount).fill().map((index, count) => (
          <li
            className={`page-item lb-page ${
              currentPage == count + 1 && 'active'
            }`}
            key={count}
          >
            <a
              className="page-link pointer"
              onClick={() => gotoPage(count + 1)}
            >
              {count + 1}
            </a>
          </li>
        ))}

        <li className="page-item lb-page">
          <a
            className={`page-link pointer ${
              currentPage === paginatedPageCount ? 'disabled' : ''
            }`}
            onClick={() => gotoNext()}
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
}
