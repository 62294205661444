import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import api from '../../api';
import { convertImageToBase64, isNull } from '../../utility';
import { parseMessage } from '../../utility/Error';

const customStyles = {
  content: {
    top: '5%',
    left: '32%',
    width: '43%',
  },
};

export default function CityModal({
  isOpen,
  closeModal,
  cityData,
  control,
  fetchCities,
  defaultCountryId,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [photo_url, setPhotoUrl] = useState('');
  const [selecedPhoto, setSelecedPhoto] = useState({});
  const [loading, setLoading] = useState(false);

  const prefillData = () => {
    setLatitude(cityData?.latitude);
    setLongitude(cityData?.longitude);
    setName(cityData?.name);
    setDescription(cityData?.description);
    setPhotoUrl(cityData?.photo_url);
  };

  const processImg = async (file) => {
    let photo_url = await convertImageToBase64(file);
    setPhotoUrl(photo_url);
    setSelecedPhoto(file);
  };

  useEffect(() => {
    prefillData();
  }, [cityData]);

  const submit = (e) => {
    e.preventDefault();

    if (control === 'Add') {
      if (
        isNull(name) ||
        isNull(defaultCountryId) ||
        isNull(description) ||
        isNull(latitude) ||
        isNull(longitude) ||
        isNull(photo_url)
      ) {
        toast.error('Please fill all the fields');
        return;
      }

      setLoading(true);

      const payload = {
        name,
        description,
        latitude,
        longitude,
        photo_url,
        country_id: defaultCountryId,
      };

      api()
        .post('/admin/city', payload)
        .then((res) => {
          toast.success('City successfully added!');
          fetchCities();
          closeModal();
        })
        .catch((err) => {
          parseMessage(err);
          setLoading(false);
        });
    } else {
      if (
        isNull(name) ||
        isNull(defaultCountryId) ||
        isNull(description) ||
        isNull(latitude) ||
        isNull(longitude) ||
        isNull(photo_url)
      ) {
        toast.error('Please fill all the fields');
        return;
      }

      setLoading(true);

      const payload = {};

      payload.name = name;
      payload.description = description;
      payload.latitude = latitude;
      payload.longitude = longitude;
      payload.country_id = defaultCountryId;

      if (!isNull(selecedPhoto?.name)) {
        payload.photo_url = photo_url;
      }

      api()
        .patch(`/admin/city/${cityData.id}`, payload)
        .then((res) => {
          toast.success('City successfully updated!');
          fetchCities();
          setLoading(false);
          closeModal();
        })
        .catch((err) => {
          parseMessage(err);
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={closeModal}
    >
      <div>
        <p className="text-center">{control} City</p>
        <form onSubmit={submit}>
          <div className="form-group">
            <label>
              City Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="name"
              required
              defaultValue={name}
              placeholder="Enter city name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              placeholder="Enter city details"
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>
                  Latitude <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="latitude"
                  required
                  defaultValue={latitude}
                  placeholder="Enter city latitude"
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Longitude <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="longitude"
                  required
                  defaultValue={longitude}
                  placeholder="Enter city longitude"
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Display Image</label>{' '}
            <span className="text-danger">*</span>
            <div>
              <input
                type="file"
                name="photourl"
                // value={photourl}
                onChange={(e) => processImg(e.target.files[0])}
              />
              <img
                src={
                  !isNull(selecedPhoto?.name)
                    ? URL.createObjectURL(selecedPhoto)
                    : photo_url
                }
                style={{
                  width: '100%',
                  height: '170px',
                  marginTop: '20px',
                }}
              />
              {/* <small className="text-info">{uploadStatus}</small> */}
            </div>
          </div>

          <div className="text-center mt-4">
            {loading && (
              <>
                <i
                  className="fas fa-circle-notch fa-spin"
                  style={{ color: '#F78567', marginRight: 20 }}
                ></i>
              </>
            )}
            {!loading && (
              <>
                <button
                  type="submit"
                  className="btn btn-md btn-primary loading m-2"
                >
                  Save Changes
                </button>
              </>
            )}
            <button
              className="btn btn-md btn-secondary"
              onClick={closeModal}
            >
              Dismiss
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
