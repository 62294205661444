import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { parseMessage } from '../../utility/Error';

export default function Auth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.WHERETO_DASH_AUTH) {
      history.push('/dashboard');
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      return toast.error('Fields cannot be empty');
    }
    setLoading(true);
    api()
      .post('/login', { email, password })
      .then((res) => {
        localStorage.setItem(
          'WHERETO_DASH_USER_v2',
          JSON.stringify(res.data.user)
        );
        localStorage.setItem(
          'WHERETO_DASH_TOKEN_v2',
          JSON.stringify(res.data.token)
        );
        setLoading(false);
        history.push('/dashboard');
      })
      .catch((err) => {
        parseMessage(err);
        setLoading(false);
      });
  };

  return (
    <>
      <section className="sign-in-page">
        <div className="container  mt-5 p-0">
          <div className="row no-gutters">
            <div className="col-sm-6 align-self-center bg-white">
              <div className="sign-in-from">
                <h1 className="mb-0">Sign in</h1>
                <p>
                  Enter your email address and password to access
                  admin panel.
                </p>
                <form className="mt-4" onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control mb-0"
                      name="email"
                      value={email}
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control mb-0"
                      name="password"
                      value={password}
                      placeholder="Enter your password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="d-inline-block w-100">
                    <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1"></div>
                    {loading ? (
                      <button
                        type="submit"
                        className="btn-primary float-right loader-btn"
                        disabled
                      >
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary float-right"
                      >
                        Sign in
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
