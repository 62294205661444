import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function AdminWrapper({ children }) {
  return (
    <div className="wrapper">
      <Sidebar />
      <Header />
      <div id="content-page" className="content-page">
        <div className="container-fluid">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
