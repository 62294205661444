import React, { useEffect, useState } from 'react';
import AdminWrapper from '../../components/common/AdminWrapper';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from '../../api';
import { parseMessage } from '../../utility/Error';
import CategoryModal from '../../components/modals/CategoryModal';

export default () => {
  const [categories, setCategory] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteKey, setDeleteKey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [control, setControl] = useState('Add');
  const [categoryData, setCategoryData] = useState({});
  const [lastKey, setLastKey] = useState('');
  const [fetchingMore, setFetchingMore] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const onSearch = (val) => {
    setQuery(val);
    let text = val.toLowerCase();
    document.querySelectorAll('#search-item').forEach((dataRow) => {
      const name = dataRow.childNodes[1].textContent;
      if (name.toLowerCase().indexOf(text) !== -1) {
        dataRow.style.display = 'table-row';
      } else {
        dataRow.style.display = 'none';
      }
    });
  };

  const getCategories = (enableLoading) => {
    if (enableLoading) {
      setLoading(true);
    }
    api()
      .get('/categories')
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setLoading(false));
  };

  const refreshPaginatedData = (data) => {
    setPerPage(data);
    getCategories(data);
  };

  const getWithoutLoading = () => {
    // db.ref('category_info/')
    //   .limitToFirst(perPage)
    //   .once('value')
    //   .then((snapshot) => {
    //     const dataObject = [];
    //     const dataItems = [];
    //     const data = snapshot.val() ? snapshot.val() : {};
    //     const keyEntries = Object.keys(data);
    //     const dataEntries = Object.entries(data);
    //     dataEntries.forEach((data) => {
    //       data.forEach((item) => {
    //         if (typeof item == 'object') {
    //           dataItems.push(item);
    //         }
    //       });
    //     });
    //     keyEntries.forEach((keyItem, index) => {
    //       let newData = dataItems[index];
    //       newData.key = keyItem;
    //       dataObject.push(newData);
    //     });
    //     const lasktKey = dataObject[dataObject.length - 1];
    //     setLastKey(lasktKey.key);
    //     setCategory(dataObject);
    //   })
    //   .catch((err) => toast.error('Something went wrong!'));
  };

  const confirmDelete = (data) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCategory(data),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteCategory = (id) => {
    setDeleting(true);
    setDeleteKey(id);
    api()
      .delete(`/admin/categories/${id}`)
      .then(() => {
        toast.success('Category information deleted!');
        getCategories(false);
      })
      .catch((err) => parseMessage(err))
      .finally(() => setDeleting(false));
  };

  const removeDelete = (id) => {};

  const setEdit = (data) => {
    setCategoryData(data);
    setControl('Edit');
    setIsOpen(true);
  };

  useEffect(() => {
    getCategories(true);
  }, []);

  let container;

  if (loading) {
    container = (
      <>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
          <td>
            <Skeleton width={80} />
          </td>
        </tr>
      </>
    );
  } else {
    if (Object.keys(categories).length > 0) {
      container = categories.map((item, index) => (
        <tr key={index} id="search-item">
          <td>{index + 1}</td>
          <td>{item.name}</td>
          {/* <td>{item.currencyUnit}</td>
          <td>{item.displayCurrencySymbol}</td> */}
          <td>
            {item.isDelete ? (
              <span className="badge badge-pill iq-bg-danger">
                Deleted
              </span>
            ) : (
              <span className="badge badge-pill iq-bg-success">
                Active
              </span>
            )}
          </td>
          <td>
            <i
              className="ri-ball-pen-fill text-success pr-1 action-badge-size"
              onClick={() => setEdit(item)}
            ></i>
            {deleteKey == item.id && deleting ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                {item.isDelete ? (
                  <span onClick={() => removeDelete(item.id)}>
                    <span
                      className="iconify text-secondary action-badge-size"
                      data-icon="ic:twotone-settings-backup-restore"
                      data-inline="false"
                    ></span>
                  </span>
                ) : (
                  <i
                    className="ri-delete-bin-5-line text-danger action-badge-size"
                    onClick={() => confirmDelete(item.id)}
                  ></i>
                )}
              </>
            )}
          </td>
        </tr>
      ));
    } else {
      container = (
        <tr>
          <td></td>
          <td></td>
          <td>No information at the moment</td>
          <td></td>
        </tr>
      );
    }
  }

  return (
    <AdminWrapper>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Categories</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <div className="row mb-3">
            <div className="col-2 mr-auto">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setIsOpen(true);
                  setCategoryData({});
                }}
              >
                Add category
              </button>
            </div>
            <div className="col-5"></div>
            <div className="col-2">
              {/* <select
                defaultValue={perPage}
                className="form-control pagination-dropdown"
                onChange={(e) =>
                  refreshPaginatedData(parseInt(e.target.value))
                }
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> */}
            </div>
            <div className="col-3 ml-auto">
              <input
                className="form-control"
                defaultValue={query}
                type="text"
                placeholder="Search category..."
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {container}
              {fetchingMore && (
                <tr>
                  <td>
                    <Skeleton width={80} />
                  </td>
                  <td>
                    <Skeleton width={80} />
                  </td>
                  <td>
                    <Skeleton width={80} />
                  </td>
                  <td>
                    <Skeleton width={80} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <CategoryModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        categoryData={categoryData}
        control={control}
        fetchCategories={() => getCategories(false)}
      />
    </AdminWrapper>
  );
};
